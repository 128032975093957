import React, {useState} from 'react'
import {Field, Form, Formik, FormikHelpers} from 'formik'
import {array, boolean, object, string} from 'yup'
import Button from './Button'
import chevronDown from '../images/chevron-down.svg'

export interface FormFields {
  title: string
  firstname: string
  lastname: string
  email: string
  phone: string
  street: string
  zip: string
  city: string
  birthdate: string
  classes: string[]
  message: string
  accepted: boolean
}

const rules = object({
  title: string().required(),
  firstname: string().required(),
  lastname: string().required(),
  email: string().required().email(),
  phone: string().required(),
  street: string().required(),
  zip: string().required(),
  city: string().required(),
  birthdate: string().required(),
  classes: array().min(1).required(),
  message: string(),
  accepted: boolean().isTrue(),
})

const courses = [
  'Klasse B (PKW)',
  'Klasse BE (PKW mit Anhänger)',
  'Klasse L (Traktor)',
  'Mofa Prüfbescheinigung (25km/h)',
  'Klasse AM (Roller 45km/h)',
  'Klasse A1 (125er)',
  'Klasse A2 (Motorräder bis 48PS)',
  'Klasse A (alle Motorräder)',
]

interface RegistrationFormProps {
  onClose: Function
}

export default function RegistrationForm({onClose}: RegistrationFormProps) {
  const [submitted, setSubmitted] = useState<boolean>(false)

  const handleSubmit = async (values: FormFields, {resetForm}: FormikHelpers<FormFields>): Promise<void> => {
    const response = await fetch(`${process.env.GATSBY_REGISTER_HANDLE_URL}`, {
      method: 'POST',
      body: JSON.stringify(values),
    })

    if (response.ok) {
      resetForm()
      setSubmitted(true)
    }
  }

  if (submitted) {
    return (
      <div className="flex flex-col items-center text-center px-4 md:px-8 p-8 gap-8">
        <div>Vielen Dank für deine Anmeldung! Wir melden uns in Kürze bei dir.</div>
        <Button
          onClick={() => {
            onClose()

            setTimeout(() => {
              setSubmitted(false)
            }, 300)
          }}
        >
          Fenster schließen
        </Button>
      </div>
    )
  }

  return (
    <>
      <Formik<FormFields>
        initialValues={{
          title: '',
          firstname: '',
          lastname: '',
          email: '',
          phone: '',
          street: '',
          zip: '',
          city: '',
          birthdate: '',
          classes: [],
          message: '',
          accepted: false,
        }}
        validationSchema={rules}
        validateOnBlur={false}
        validateOnMount={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({errors, isSubmitting}) => (
          <Form>
            <div className="px-4 md:px-8 p-8">
              <div className="flex flex-col space-y-16">
                <span className="text-lg">
                  Im folgenden Formular kannst du dich vorab zum Führerschein anmelden. Bitte beachte, dass du für eine verbindliche Anmeldung im Büro eine Unterschrift leisten musst.
                  Mit deinen Daten bereiten wir gerne schon alles für dich vor.
                </span>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <div className="col-span-2 md:col-span-1 flex flex-col space-y-1">
                    <Field
                      as="select"
                      name="title"
                      placeholder="Anrede"
                      disabled={isSubmitting}
                      className="appearance-none dark:text-ci-yellow disabled:opacity-50 outline-none bg-transparent rounded-lg border border-[#CECECE] p-4"
                      style={{
                        backgroundImage: `url(${chevronDown})`,
                        backgroundPosition: 'center right 5px',
                        backgroundRepeat: 'no-repeat',
                      }}
                    >
                      <option value="" disabled>Anrede</option>
                      <option value="Herr">Herr</option>
                      <option value="Frau">Frau</option>
                      <option value="Divers">Divers</option>
                    </Field>
                    {errors.title && (
                      <div className="text-sm text-red-500">
                        Pflichtfeld
                      </div>
                    )}
                  </div>
                  <div className="col-span-2 md:col-span-1 flex flex-col space-y-1">
                    <Field
                      type="text"
                      name="firstname"
                      placeholder="Vorname"
                      disabled={isSubmitting}
                      className="dark:text-ci-yellow disabled:opacity-50 outline-none bg-transparent rounded-lg border border-[#CECECE] p-4"
                    />
                    {errors.firstname && (
                      <div className="text-sm text-red-500">
                        Pflichtfeld
                      </div>
                    )}
                  </div>
                  <div className="col-span-2 md:col-span-1 flex flex-col space-y-1">
                    <Field
                      type="text"
                      name="lastname"
                      placeholder="Nachname"
                      disabled={isSubmitting}
                      className="dark:text-ci-yellow disabled:opacity-50 outline-none bg-transparent rounded-lg border border-[#CECECE] p-4"
                    />
                    {errors.lastname && (
                      <div className="text-sm text-red-500">
                        Pflichtfeld
                      </div>
                    )}
                  </div>
                  <div className="col-span-2 md:col-span-1 flex flex-col space-y-1">
                    <Field
                      type="email"
                      name="email"
                      placeholder="E-Mail"
                      disabled={isSubmitting}
                      className="dark:text-ci-yellow disabled:opacity-50 outline-none bg-transparent rounded-lg border border-[#CECECE] p-4"
                    />
                    {errors.email && (
                      <div className="text-sm text-red-500">
                        Gitte gebe deine E-Mail an
                      </div>
                    )}
                  </div>
                  <div className="col-span-2 md:col-span-1 flex flex-col space-y-1">
                    <Field
                      type="text"
                      name="phone"
                      placeholder="Telefon"
                      disabled={isSubmitting}
                      className="dark:text-ci-yellow disabled:opacity-50 outline-none bg-transparent rounded-lg border border-[#CECECE] p-4"
                    />
                    {errors.phone && (
                      <div className="text-sm text-red-500">
                        Gitte gebe deine Telefonnummer an
                      </div>
                    )}
                  </div>
                  <div className="col-span-2 md:col-span-1 flex flex-col space-y-1">
                    <Field
                      type="text"
                      name="birthdate"
                      placeholder="Geburtsdatum"
                      disabled={isSubmitting}
                      className="dark:text-ci-yellow disabled:opacity-50 outline-none bg-transparent rounded-lg border border-[#CECECE] p-4"
                    />
                    {errors.birthdate && (
                      <div className="text-sm text-red-500">
                        Gitte gebe dein Geburtsdatum an
                      </div>
                    )}
                  </div>
                  <div className="col-span-2">
                    Anschrift:
                  </div>
                  <div className="col-span-2 md:col-span-1 flex flex-col space-y-1">
                    <Field
                      type="text"
                      name="street"
                      placeholder="Straße"
                      disabled={isSubmitting}
                      className="dark:text-ci-yellow disabled:opacity-50 outline-none bg-transparent rounded-lg border border-[#CECECE] p-4"
                    />
                    {errors.street && (
                      <div className="text-sm text-red-500">
                        Bitte gebe deine Straße an
                      </div>
                    )}
                  </div>
                  <div className="col-span-2 md:col-span-1 flex flex-col space-y-1">
                    <Field
                      type="text"
                      name="zip"
                      placeholder="Postleitzahl"
                      disabled={isSubmitting}
                      className="dark:text-ci-yellow disabled:opacity-50 outline-none bg-transparent rounded-lg border border-[#CECECE] p-4"
                    />
                    {errors.zip && (
                      <div className="text-sm text-red-500">
                        Bitte gebe deine Postleitzahl an
                      </div>
                    )}
                  </div>
                  <div className="col-span-2 md:col-span-1 flex flex-col space-y-1">
                    <Field
                      type="text"
                      name="city"
                      placeholder="Wohnort"
                      disabled={isSubmitting}
                      className="dark:text-ci-yellow disabled:opacity-50 outline-none bg-transparent rounded-lg border border-[#CECECE] p-4"
                    />
                    {errors.city && (
                      <div className="text-sm text-red-500">
                        Bitte gebe deinen Wohnort
                      </div>
                    )}
                  </div>
                  <div className="col-span-2 flex flex-col space-y-1">
                    <span>
                      Für welche Führerscheinklassen möchtest du dich anmelden?
                    </span>
                    <small className="opacity-50">Mehrfachauswahl möglich</small>
                  </div>
                  <div className="col-span-2 flex flex-col space-y-1 -mt-6">
                    <Field
                      as="select"
                      name="classes"
                      placeholder="Führerscheinklassen"
                      rows={5}
                      disabled={isSubmitting}
                      className="appearance-none dark:text-ci-yellow disabled:opacity-50 outline-none bg-transparent rounded-lg border border-[#CECECE] p-4"
                      multiple
                    >
                      {courses.map((course: string, index: number) => (
                        <option value={course} key={index}>{course}</option>
                      ))}
                    </Field>
                    {errors.classes && (
                      <div className="text-sm text-red-500">
                        Bitte wähle mindestens eine Führerscheinklasse aus
                      </div>
                    )}
                  </div>
                  <div className="col-span-2 flex flex-col space-y-1">
                    <Field
                      component="textarea"
                      name="message"
                      placeholder="Deine Nachricht"
                      rows={5}
                      disabled={isSubmitting}
                      className="dark:text-ci-yellow disabled:opacity-50 outline-none bg-transparent rounded-lg border border-[#CECECE] p-4"
                    />
                    <small className="pl-4 opacity-50">optional</small>
                  </div>
                  <div className="flex flex-col space-y-8 md:flex-row md:space-y-0 md:space-x-8 md:col-span-2">
                    <div className="flex-1 flex space-x-4 items-start">
                      <Field
                        type="checkbox"
                        name="accepted"
                        id="registration_accepted"
                        disabled={isSubmitting}
                        className="h-12 w-12"
                      />
                      <div className="flex flex-col space-y-2">
                        <label htmlFor="registration_accepted" className="mt-2">Ich habe die <a href="/datenschutz" target="_blank" className="underline">Datenschutzbestimmungen</a> gelesen und akzeptiere diese.</label>
                        {errors.accepted && (
                          <span className="text-red-500">
                            Bitte akzeptiere die Datenschutzbestimmungen
                          </span>
                        )}
                      </div>
                    </div>
                    <div>
                      <Button
                        type="submit"
                        className="!py-4 !px-8"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? 'sende...' : 'anmelden'}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
