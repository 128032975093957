import React, {HTMLProps, ReactNode, useState} from 'react'

export interface FAQItemProps extends HTMLProps<HTMLDivElement> {
  question: string
}

export default function FAQItem({question, children}: FAQItemProps) {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <div className="rounded-lg border border-[#CECECE]">
      <div
        className={`flex items-center space-x-4 text-lg px-8 py-4 cursor-pointer hover:bg-gradient-light-end dark:hover:bg-ci-gray ${open ? 'bg-gradient-light-end dark:bg-ci-gray rounded-t-lg' : 'rounded-lg'}`}
        onClick={() => setOpen(open => !open)}
      >
        <h3 className="flex-1 select-none">{question}</h3>
        <svg className={`${open ? 'rotate-180': 'rotate-0'} transition-all`} xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8">
          <path fill="#2C2E33" className="dark:fill-ci-yellow" d="M6.99945315,8 C6.70094787,8 6.40229676,7.88839286 6.1748086,7.66517857 L0.341779074,1.95089286 C-0.113926358,1.50446429 -0.113926358,0.78125 0.341779074,0.334821429 C0.797484506,-0.111607143 1.53572731,-0.111607143 1.99143274,0.334821429 L6.99945315,5.24285714 L12.0085673,0.335714286 C12.4642727,-0.110714286 13.2025155,-0.110714286 13.6582209,0.335714286 C14.1139264,0.782142857 14.1139264,1.50535714 13.6582209,1.95178571 L7.8251914,7.66607143 C7.59733868,7.88928571 7.29839592,8 6.99945315,8 Z" transform="matrix(-1 0 0 1 14 0)"/>
        </svg>
      </div>
      <div className={`overflow-hidden ${open ? 'max-h-[2000px]' : 'max-h-0'} transition-all`}>
        <div className="px-8 py-4">
          {children}
        </div>
      </div>
    </div>
  )
}
