import React, {HTMLProps, ReactNode} from 'react'

export interface CourseProps extends HTMLProps<HTMLDivElement> {
  title: string
  abbr: string
  icon: ReactNode
}

export default function Course({title, abbr, icon, children, className = '', ...props}: CourseProps) {
  return (
    <div
      className={`flex space-x-4 ${className}`}
      {...props}
    >
      <div className="w-20 h-20 flex-shrink-0 flex flex-col items-center justify-center space-y-2 rounded-md bg-ci-gray text-ci-yellow">
        {icon}
        <span>{abbr}</span>
      </div>
      <div className="flex flex-col space-y-1">
        <span className="text-xl 2xl:text-2xl">{title}</span>
        <div className="text-xs 2xl:text-sm">
          {children}
        </div>
      </div>
    </div>
  )
}
