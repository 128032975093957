import React, {HTMLProps} from 'react'
import Dots from './Dots'

export interface HeadingProps extends HTMLProps<HTMLHeadingElement> {
  dotsContainerClassName?: string
}

export default function Heading({as = 'h2', className = '', children, dotsContainerClassName = '', ...props}: HeadingProps) {
  const Component: any = as

  return (
    <Component
      className={`relative inline overflow-visible ${className}`}
      {...props}
    >
      <span className="relative z-10">{children}</span>
      <div className={`absolute z-[0] -left-8 -top-8 ${dotsContainerClassName}`}>
        <Dots />
      </div>
    </Component>
  )
}
