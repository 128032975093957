import React from 'react'
import FAQItem from './FAQItem'

export default function FAQ() {
  return (
    <div className="flex flex-col space-y-6">
      <FAQItem question="Was kostet mich mein neuer Führerschein?">
        <div className="flex flex-col space-y-4">
          <p>
            Wieviel dein Führerschein kostet ist natürlich ganz von dir persönlich und deiner Vorbildung abhängig. Damit will ich sagen: nicht alle Menschen lernen gleich schnell.
          </p>
          <p className="font-semibold">
            Insbesondere wenn ihr euch für eine Doppelklasse entscheidet, könnt ihr immer an der Grundgebühr sparen. Da es verschiedene Kombinationsmöglichkeiten gibt, fragt einfach bei uns nach.
          </p>
        </div>
      </FAQItem>
      <FAQItem question="Wann beginnt man eigentlich mit der Ausbildung?">
        <div className="flex flex-col space-y-4">
          <p>
            Wenn ihr pünktlich zu eurem Geburtstag den Führerschein haben wollt, meldet euch lieber etwas früher an. Ein halbes Jahr vor dem Geburtstag wäre perfekt,
            damit wir nicht am Ende Terminprobleme bekommen. Auch die Behörde (Führerscheinstelle) braucht 1 – 2 Wochen um den Antrag zu bearbeiten.
          </p>
          <p>
            Der Einstieg in den Theorieunterricht kann jederzeit erfolgen.
          </p>
        </div>
      </FAQItem>
      <FAQItem question="Was brauche ich bei der Anmeldung?">
        <p>
          Zur Anmeldung brauchst du nur deinen Personalausweis.
        </p>
      </FAQItem>
      <FAQItem question="Wo finden die Prüfungen statt?">
        <div className="flex flex-col space-y-4">
          <p>
            Die theoretische Prüfung findet beim TÜV in Fulda statt, die praktische Prüfung in Hünfeld.
          </p>
          <p>
            Termine für Prüfungen werden individuell mit euch besprochen!
          </p>
        </div>
      </FAQItem>
      <FAQItem question="Begleitendes Fahren mit 17 - wer darf Begleitperson sein ?">
        <div className="flex flex-col space-y-4">
          <p>
            Die Begleitpersonen müssen mindestens 30 Jahre alt sein und seit 5 Jahren die Fahrerlaubnis Klasse B (oder 3) besitzen.
          </p>
          <p>
            Weiterhin darf im Verkehrszentralregister (Flensburg) zum Zeitpunkt der Antragstellung nicht mehr als 1 Punkt registriert sein!
          </p>
        </div>
      </FAQItem>
    </div>
  )
}
