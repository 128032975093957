import React, {HTMLProps} from 'react'

export default function Dots(props: HTMLProps<SVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="265" height="225" viewBox="0 0 265 225">
      <g fill="#F1F1F1" className="dark:fill-[#2E2F37]" {...props}>
        <rect width="5" height="5" rx="2.5"/>
        <rect width="5" height="5" x="60" rx="2.5"/>
        <rect width="5" height="5" x="120" rx="2.5"/>
        <rect width="5" height="5" x="20" rx="2.5"/>
        <rect width="5" height="5" x="80" rx="2.5"/>
        <rect width="5" height="5" x="140" rx="2.5"/>
        <rect width="5" height="5" x="40" rx="2.5"/>
        <rect width="5" height="5" x="100" rx="2.5"/>
        <rect width="5" height="5" x="160" rx="2.5"/>
        <rect width="5" height="5" x="180" rx="2.5"/>
        <rect width="5" height="5" y="20" rx="2.5"/>
        <rect width="5" height="5" x="60" y="20" rx="2.5"/>
        <rect width="5" height="5" x="120" y="20" rx="2.5"/>
        <rect width="5" height="5" x="20" y="20" rx="2.5"/>
        <rect width="5" height="5" x="80" y="20" rx="2.5"/>
        <rect width="5" height="5" x="140" y="20" rx="2.5"/>
        <rect width="5" height="5" x="40" y="20" rx="2.5"/>
        <rect width="5" height="5" x="100" y="20" rx="2.5"/>
        <rect width="5" height="5" x="160" y="20" rx="2.5"/>
        <rect width="5" height="5" x="180" y="20" rx="2.5"/>
        <rect width="5" height="5" y="40" rx="2.5"/>
        <rect width="5" height="5" x="60" y="40" rx="2.5"/>
        <rect width="5" height="5" x="120" y="40" rx="2.5"/>
        <rect width="5" height="5" x="20" y="40" rx="2.5"/>
        <rect width="5" height="5" x="80" y="40" rx="2.5"/>
        <rect width="5" height="5" x="140" y="40" rx="2.5"/>
        <rect width="5" height="5" x="40" y="40" rx="2.5"/>
        <rect width="5" height="5" x="100" y="40" rx="2.5"/>
        <rect width="5" height="5" x="160" y="40" rx="2.5"/>
        <rect width="5" height="5" x="180" y="40" rx="2.5"/>
        <rect width="5" height="5" y="60" rx="2.5"/>
        <rect width="5" height="5" x="60" y="60" rx="2.5"/>
        <rect width="5" height="5" x="120" y="60" rx="2.5"/>
        <rect width="5" height="5" x="20" y="60" rx="2.5"/>
        <rect width="5" height="5" x="80" y="60" rx="2.5"/>
        <rect width="5" height="5" x="140" y="60" rx="2.5"/>
        <rect width="5" height="5" x="40" y="60" rx="2.5"/>
        <rect width="5" height="5" x="100" y="60" rx="2.5"/>
        <rect width="5" height="5" x="160" y="60" rx="2.5"/>
        <rect width="5" height="5" x="180" y="60" rx="2.5"/>
        <rect width="5" height="5" y="80" rx="2.5"/>
        <rect width="5" height="5" x="60" y="80" rx="2.5"/>
        <rect width="5" height="5" x="120" y="80" rx="2.5"/>
        <rect width="5" height="5" x="20" y="80" rx="2.5"/>
        <rect width="5" height="5" x="80" y="80" rx="2.5"/>
        <rect width="5" height="5" x="140" y="80" rx="2.5"/>
        <rect width="5" height="5" x="40" y="80" rx="2.5"/>
        <rect width="5" height="5" x="100" y="80" rx="2.5"/>
        <rect width="5" height="5" x="160" y="80" rx="2.5"/>
        <rect width="5" height="5" x="180" y="80" rx="2.5"/>
        <rect width="5" height="5" y="100" rx="2.5"/>
        <rect width="5" height="5" x="60" y="100" rx="2.5"/>
        <rect width="5" height="5" x="120" y="100" rx="2.5"/>
        <rect width="5" height="5" x="20" y="100" rx="2.5"/>
        <rect width="5" height="5" x="80" y="100" rx="2.5"/>
        <rect width="5" height="5" x="140" y="100" rx="2.5"/>
        <rect width="5" height="5" x="40" y="100" rx="2.5"/>
        <rect width="5" height="5" x="100" y="100" rx="2.5"/>
        <rect width="5" height="5" x="160" y="100" rx="2.5"/>
        <rect width="5" height="5" x="180" y="100" rx="2.5"/>
        <rect width="5" height="5" y="120" rx="2.5"/>
        <rect width="5" height="5" x="60" y="120" rx="2.5"/>
        <rect width="5" height="5" x="120" y="120" rx="2.5"/>
        <rect width="5" height="5" x="20" y="120" rx="2.5"/>
        <rect width="5" height="5" x="80" y="120" rx="2.5"/>
        <rect width="5" height="5" x="140" y="120" rx="2.5"/>
        <rect width="5" height="5" x="40" y="120" rx="2.5"/>
        <rect width="5" height="5" x="100" y="120" rx="2.5"/>
        <rect width="5" height="5" x="160" y="120" rx="2.5"/>
        <rect width="5" height="5" x="180" y="120" rx="2.5"/>
        <rect width="5" height="5" y="140" rx="2.5"/>
        <rect width="5" height="5" x="60" y="140" rx="2.5"/>
        <rect width="5" height="5" x="120" y="140" rx="2.5"/>
        <rect width="5" height="5" x="20" y="140" rx="2.5"/>
        <rect width="5" height="5" x="80" y="140" rx="2.5"/>
        <rect width="5" height="5" x="140" y="140" rx="2.5"/>
        <rect width="5" height="5" x="40" y="140" rx="2.5"/>
        <rect width="5" height="5" x="100" y="140" rx="2.5"/>
        <rect width="5" height="5" x="160" y="140" rx="2.5"/>
        <rect width="5" height="5" x="180" y="140" rx="2.5"/>
        <rect width="5" height="5" y="160" rx="2.5"/>
        <rect width="5" height="5" x="60" y="160" rx="2.5"/>
        <rect width="5" height="5" x="120" y="160" rx="2.5"/>
        <rect width="5" height="5" x="20" y="160" rx="2.5"/>
        <rect width="5" height="5" x="80" y="160" rx="2.5"/>
        <rect width="5" height="5" x="140" y="160" rx="2.5"/>
        <rect width="5" height="5" x="40" y="160" rx="2.5"/>
        <rect width="5" height="5" x="100" y="160" rx="2.5"/>
        <rect width="5" height="5" x="160" y="160" rx="2.5"/>
        <rect width="5" height="5" x="180" y="160" rx="2.5"/>
        <rect width="5" height="5" y="180" rx="2.5"/>
        <rect width="5" height="5" x="60" y="180" rx="2.5"/>
        <rect width="5" height="5" x="120" y="180" rx="2.5"/>
        <rect width="5" height="5" x="20" y="180" rx="2.5"/>
        <rect width="5" height="5" x="80" y="180" rx="2.5"/>
        <rect width="5" height="5" x="140" y="180" rx="2.5"/>
        <rect width="5" height="5" x="40" y="180" rx="2.5"/>
        <rect width="5" height="5" x="100" y="180" rx="2.5"/>
        <rect width="5" height="5" x="160" y="180" rx="2.5"/>
        <rect width="5" height="5" x="180" y="180" rx="2.5"/>
        <g transform="translate(80 40)">
          <rect width="5" height="5" rx="2.5"/>
          <rect width="5" height="5" x="60" rx="2.5"/>
          <rect width="5" height="5" x="120" rx="2.5"/>
          <rect width="5" height="5" x="20" rx="2.5"/>
          <rect width="5" height="5" x="80" rx="2.5"/>
          <rect width="5" height="5" x="140" rx="2.5"/>
          <rect width="5" height="5" x="40" rx="2.5"/>
          <rect width="5" height="5" x="100" rx="2.5"/>
          <rect width="5" height="5" x="160" rx="2.5"/>
          <rect width="5" height="5" x="180" rx="2.5"/>
          <rect width="5" height="5" y="20" rx="2.5"/>
          <rect width="5" height="5" x="60" y="20" rx="2.5"/>
          <rect width="5" height="5" x="120" y="20" rx="2.5"/>
          <rect width="5" height="5" x="20" y="20" rx="2.5"/>
          <rect width="5" height="5" x="80" y="20" rx="2.5"/>
          <rect width="5" height="5" x="140" y="20" rx="2.5"/>
          <rect width="5" height="5" x="40" y="20" rx="2.5"/>
          <rect width="5" height="5" x="100" y="20" rx="2.5"/>
          <rect width="5" height="5" x="160" y="20" rx="2.5"/>
          <rect width="5" height="5" x="180" y="20" rx="2.5"/>
          <rect width="5" height="5" y="40" rx="2.5"/>
          <rect width="5" height="5" x="60" y="40" rx="2.5"/>
          <rect width="5" height="5" x="120" y="40" rx="2.5"/>
          <rect width="5" height="5" x="20" y="40" rx="2.5"/>
          <rect width="5" height="5" x="80" y="40" rx="2.5"/>
          <rect width="5" height="5" x="140" y="40" rx="2.5"/>
          <rect width="5" height="5" x="40" y="40" rx="2.5"/>
          <rect width="5" height="5" x="100" y="40" rx="2.5"/>
          <rect width="5" height="5" x="160" y="40" rx="2.5"/>
          <rect width="5" height="5" x="180" y="40" rx="2.5"/>
          <rect width="5" height="5" y="60" rx="2.5"/>
          <rect width="5" height="5" x="60" y="60" rx="2.5"/>
          <rect width="5" height="5" x="120" y="60" rx="2.5"/>
          <rect width="5" height="5" x="20" y="60" rx="2.5"/>
          <rect width="5" height="5" x="80" y="60" rx="2.5"/>
          <rect width="5" height="5" x="140" y="60" rx="2.5"/>
          <rect width="5" height="5" x="40" y="60" rx="2.5"/>
          <rect width="5" height="5" x="100" y="60" rx="2.5"/>
          <rect width="5" height="5" x="160" y="60" rx="2.5"/>
          <rect width="5" height="5" x="180" y="60" rx="2.5"/>
          <rect width="5" height="5" y="80" rx="2.5"/>
          <rect width="5" height="5" x="60" y="80" rx="2.5"/>
          <rect width="5" height="5" x="120" y="80" rx="2.5"/>
          <rect width="5" height="5" x="20" y="80" rx="2.5"/>
          <rect width="5" height="5" x="80" y="80" rx="2.5"/>
          <rect width="5" height="5" x="140" y="80" rx="2.5"/>
          <rect width="5" height="5" x="40" y="80" rx="2.5"/>
          <rect width="5" height="5" x="100" y="80" rx="2.5"/>
          <rect width="5" height="5" x="160" y="80" rx="2.5"/>
          <rect width="5" height="5" x="180" y="80" rx="2.5"/>
          <rect width="5" height="5" y="100" rx="2.5"/>
          <rect width="5" height="5" x="60" y="100" rx="2.5"/>
          <rect width="5" height="5" x="120" y="100" rx="2.5"/>
          <rect width="5" height="5" x="20" y="100" rx="2.5"/>
          <rect width="5" height="5" x="80" y="100" rx="2.5"/>
          <rect width="5" height="5" x="140" y="100" rx="2.5"/>
          <rect width="5" height="5" x="40" y="100" rx="2.5"/>
          <rect width="5" height="5" x="100" y="100" rx="2.5"/>
          <rect width="5" height="5" x="160" y="100" rx="2.5"/>
          <rect width="5" height="5" x="180" y="100" rx="2.5"/>
          <rect width="5" height="5" y="120" rx="2.5"/>
          <rect width="5" height="5" x="60" y="120" rx="2.5"/>
          <rect width="5" height="5" x="120" y="120" rx="2.5"/>
          <rect width="5" height="5" x="20" y="120" rx="2.5"/>
          <rect width="5" height="5" x="80" y="120" rx="2.5"/>
          <rect width="5" height="5" x="140" y="120" rx="2.5"/>
          <rect width="5" height="5" x="40" y="120" rx="2.5"/>
          <rect width="5" height="5" x="100" y="120" rx="2.5"/>
          <rect width="5" height="5" x="160" y="120" rx="2.5"/>
          <rect width="5" height="5" x="180" y="120" rx="2.5"/>
          <rect width="5" height="5" y="140" rx="2.5"/>
          <rect width="5" height="5" x="60" y="140" rx="2.5"/>
          <rect width="5" height="5" x="120" y="140" rx="2.5"/>
          <rect width="5" height="5" x="20" y="140" rx="2.5"/>
          <rect width="5" height="5" x="80" y="140" rx="2.5"/>
          <rect width="5" height="5" x="140" y="140" rx="2.5"/>
          <rect width="5" height="5" x="40" y="140" rx="2.5"/>
          <rect width="5" height="5" x="100" y="140" rx="2.5"/>
          <rect width="5" height="5" x="160" y="140" rx="2.5"/>
          <rect width="5" height="5" x="180" y="140" rx="2.5"/>
          <rect width="5" height="5" y="160" rx="2.5"/>
          <rect width="5" height="5" x="60" y="160" rx="2.5"/>
          <rect width="5" height="5" x="120" y="160" rx="2.5"/>
          <rect width="5" height="5" x="20" y="160" rx="2.5"/>
          <rect width="5" height="5" x="80" y="160" rx="2.5"/>
          <rect width="5" height="5" x="140" y="160" rx="2.5"/>
          <rect width="5" height="5" x="40" y="160" rx="2.5"/>
          <rect width="5" height="5" x="100" y="160" rx="2.5"/>
          <rect width="5" height="5" x="160" y="160" rx="2.5"/>
          <rect width="5" height="5" x="180" y="160" rx="2.5"/>
          <rect width="5" height="5" y="180" rx="2.5"/>
          <rect width="5" height="5" x="60" y="180" rx="2.5"/>
          <rect width="5" height="5" x="120" y="180" rx="2.5"/>
          <rect width="5" height="5" x="20" y="180" rx="2.5"/>
          <rect width="5" height="5" x="80" y="180" rx="2.5"/>
          <rect width="5" height="5" x="140" y="180" rx="2.5"/>
          <rect width="5" height="5" x="40" y="180" rx="2.5"/>
          <rect width="5" height="5" x="100" y="180" rx="2.5"/>
          <rect width="5" height="5" x="160" y="180" rx="2.5"/>
          <rect width="5" height="5" x="180" y="180" rx="2.5"/>
        </g>
      </g>
    </svg>

  )
}
