import React, {HTMLProps} from 'react'

export interface HighlightProps extends HTMLProps<HTMLDivElement> {
}


export default function Highlight({as = 'span', className = '', children, ...props}: HighlightProps) {
  const Component: any = as

  return (
    <Component
      className={`relative inline z-10 bg-ci-gray text-ci-yellow px-2 ${className}`}
      {...props}
    >
      {children}
    </Component>
  )
}
