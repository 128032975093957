import React from 'react'
import {createPortal} from 'react-dom'
import Button from './Button'

export interface ThanksMessageProps {
  visible: boolean
  onClose: Function
}

export default function ThanksMessage({visible = false, onClose}: ThanksMessageProps) {
  if (typeof window === 'undefined') {
    return null
  }

  return createPortal((
    <div
      className={`
        fixed flex items-center justify-center top-0 right-0 bottom-0 left-0 bg-white/50 transition-all
        ${visible ? 'z-[1000]' : 'z-[-1000] opacity-0 delay-300'}
      `}
    >
      <div
        className={`
          flex flex-col space-y-8 rounded-md w-[90vw] max-w-[400px] p-8 bg-ci-gray border border-gradient-light-end shadow-xl text-light-text transition-all duration-500
          ${visible ? 'translate-y-0' : 'translate-y-[100px]'}
        `}
      >
        <h3 className="text-2xl font-semibold text-ci-yellow">Vielen Dank!</h3>
        <p>
          Wir werden uns in Kürze bei dir melden!
        </p>
        <div>
          <Button
            onClick={() => onClose()}
          >
            schließen
          </Button>
        </div>
      </div>
    </div>
  ), document.body)
}
