import React, {ReactNode, useEffect, useRef, useState} from 'react'
import {createPortal} from 'react-dom'
import {Helmet} from 'react-helmet'
import Highlight from './Highlight'
import {useAppContext} from '../context'
import GradientContainer from './GradientContainer'

export interface ModalProps {
  title?: ReactNode
  open: boolean
  width?: number
  onClose?: Function
  children: ReactNode
}

export default function Modal(
  {
    title,
    open,
    width = 768,
    onClose = () => {},
    children
  }: ModalProps
) {
  const {darkMode} = useAppContext()
  const [isClient, setIsClient] = useState<boolean>(false)
  const backdrop = useRef<HTMLDivElement>(null)
  const [defaultClassName, setDefaultClassName] = useState<string>('')

  // Not setting those with helmet to not interfere with dark mode props
  useEffect(() => {
    const html = document.querySelector('html')!

    if (open) {
      html.classList.add('overflow-hidden')
    } else {
      html.classList.remove('overflow-hidden')
    }
  }, [open])

  const keyListener = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      onClose()
    }
  }

  useEffect(() => {
    setIsClient(typeof window !== 'undefined')
  }, [])

  useEffect(() => {
    window?.addEventListener('keyup', keyListener)

    return () => {
      window?.removeEventListener('keyup', keyListener)
    }
  }, [])

  if (!isClient) {
    return null
  }

  return createPortal(
    <>
      <div
        ref={backdrop}
        className={`
          backdrop fixed h-full h-dvh inset-0 flex items-center justify-center transition-all duration-500
          ${darkMode ? 'md:bg-white/50' : 'md:bg-black/50'} 
          ${open ? 'z-[1000]' : 'z-[-1000] opacity-0 md:delay-150'}
        `}
        onClick={event => {
          if (event.target === backdrop.current) {
            onClose()
          }
        }}
      >
        <GradientContainer
          className={`
            absolute h-full h-dvh md:h-auto max-h-full overflow-y-scroll !transition-all w-full md:relative max-md:top-0 max-md:right-0 max-md:bottom-0 max-md:left-0 z-20 md:rounded-lg flex flex-col space-y-4 max-w-full shadow-2xl text-ci duration-300
            ${darkMode ? 'bg-ci-gray text-white' : 'bg-white text-ci-gray'}
            ${open ? 'opacity-1 translate-y-0 md:delay-150' : 'opacity-0 translate-y-16'}
          `}
          style={{
            maxWidth: width,
          }}
          containerProps={{
            className: '!py-4'
          }}
        >
          <div className="relative items-center">
            {title && (
              <h3 className="relative text-xl md:text-3xl md:rounded-t-lg p-4 uppercase lg:text-center font-bold bg-ci">
                <Highlight><span>{title}</span></Highlight>
              </h3>
            )}
            <div
              className={`cursor-pointer absolute ${title ? 'top-5 md:top-6' : 'top-4'} right-6`}
              onClick={() => onClose()}
            >
              <svg width={title ? 20 : 14} height={title ? 20 : 14} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeWidth="4.12" className={darkMode ? 'stroke-ci-yellow' : 'stroke-ci-gray'}>
                  <path d="m33.88 2.06-31.82 31.82"/>
                  <path d="m2.06 2.06 31.82 31.82"/>
                </g>
              </svg>
            </div>
          </div>
          {children}
        </GradientContainer>
      </div>
    </>,
    document?.body
  )
}
